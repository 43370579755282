import React from "react"
import {Link} from "gatsby"
import SiteLayout from "../components/sitelayout"
import Header from "../components/header"
import SEO from "../components/seo"
import Button from "../components/button"
import Container from "../components/container"
import TextDiv from "../components/textdiv"
import Section from "../components/section"
import Segment, {SegmentSummary, SegmentSubTitle} from "../components/segment"
import HelpLink from "../components/helplink"

import { useTheme } from "../services/theme"

const Privacy = () => {

    const theme = useTheme()
    return (
        <SiteLayout
        signup={
            <Link to="/tools/hubspot-webflow-form-tool"><Button secondary="true">Get free tools</Button></Link>
        }
        >
            <SEO title="Privacy"/>
            <Container>
                <Header
                paddingTop="20px"
                title="Privacy policy"
                align="left"
                />
            </Container>
            <Section>
                <p>
                    This Privacy Policy applies to the information we collect when you use our websites (including but not limited to vimkit.com,
                    vimkit.io and vimkit.app) and any products and services that are available on or through our websites.
                    Throughout this Privacy Policy we’ll refer to our website and other products and services collectively as “Services”. 
                    If you are a user or visitor of a site which use our Services ("Customer Site”),
                    the ‘End Users’ section of this privacy policy applies to you.
                    If you have suggestions or questions your feedback is welcome at legal@vimkit.com.
                </p>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We collect information in three ways.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Information we collect</SegmentSubTitle>
                    <p>
                        We only collect information about you if we have a reason to do so–for example, to provide our Services,
                        to communicate with you, or to make our Services better.
                        We destroy your data as soon as it's not longer needed for the purposes you agreed to when you provided it.
                        We collect information in three ways: if and when you provide information to us, automatically through operating our Services,
                        and from outside sources.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            First, when you provide it to us.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Information you provide</SegmentSubTitle>
                    <p>
                        The amount and type of information depends on the context and how we use the information. Here are some examples:
                    </p>
                    <ul>
                        <li>
                            Registration information: We ask for basic information from you in order to set up your account - for example,
                            your name, email address, phone number and profile picture.
                        </li>
                        <li>
                            Transaction and billing information: If you buy something from us, you will provide additional personal
                            and payment information that is required to process the transaction and your payment, such as your name,
                            credit card information, and contact information. We process this data, but it is stored with a compliant 3rd party.
                        </li>
                        <li>
                            User generated content: In connection with using the Services, you may generate content that provides us
                            with additional information about you.
                        </li>
                        <li>
                            Credentials: In connection with using the Services, you may provide us with credentials for your website
                             or your account on a third-party service (like SSH, FTP, and SFTP username and password, API key or OAuth credential).
                        </li>
                        <li>
                            Communications with us: You may also provide us information when you respond to surveys or
                            communicate with us about a support question.
                        </li>
                    </ul>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Second, when you use our services.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Information we collect automatically</SegmentSubTitle>
                    <p>
                        We also collect some information automatically.
                    </p>
                    <ul>
                        <li>
                            Log information: Like most online service providers, we collect information that web browsers,
                            mobile devices, and servers typically make available, such as the browser type, IP address,
                            unique device identifiers, language preference, referring site, the date and time of access,
                            operating system, and mobile network information. We collect log information when you use our Services.
                        </li>
                        <li>
                            Usage information: We collect information about your usage of our Services.
                            For example, we collect information about the actions that site administrators and users perform on a site–in other words,
                            who did what, when and to what thing on a site (e.g., [username] deleted “[content]” at [time/date]).
                            We also collect information about what happens when you use our Services along with information about your device
                            (e.g., mobile screen size, name of cellular network, and mobile device manufacturer). We use this information to,
                            for example, provide our Services to you, as well as get insights on how people use our Services,
                            so we can make our Services better.
                        </li>
                        <li>
                            Location information: We may determine the approximate location of your device from your IP address.
                            We collect and use this information to, for example, calculate how many people visit our Services from
                            certain geographic regions. We may also collect information about your precise location via our mobile apps
                            (when, for example, you post a photograph with location information) if you allow us to do so through your
                            mobile device operating system’s permissions.
                        </li>
                        <li>
                            Information from cookies & similar technologies: A cookie is a string of information that a website stores
                            on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns.
                            Pixel tags (also called web beacons) are small blocks of code placed on websites and e-mails.
                            We use cookies and similar technologies like pixel tags to help us identify and track visitors,
                            usage, and access preferences for our Services. See the opt out of cookies section of this policy for information
                            on how control or opt out of cookies.
                        </li>
                    </ul>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Third, when a third party provides it.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Information from other sources</SegmentSubTitle>
                    <p>
                        We may also get information about you from other sources. For example, if you connect your account to a third-party service provider,
                        we may receive information from that service. The information we receive depends on which services you authorize and
                        any options that are available. We may also get information from third party services about individuals who are not yet our users,
                        which we may use, for example, for marketing and advertising purposes.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We use the information to serve you.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>How we use information</SegmentSubTitle>
                    <p>
                        We use information about you as mentioned above and as follows:
                    </p>
                    <ul>
                        <li>
                            To provide our Services–for example, to set up and maintain your account, backup and restore your content,
                            charge you for any of our paid Services, and provide you support for the Services.
                        </li>
                        <li>To further develop our Services–for example by adding new features that we think our users will enjoy or find useful.</li>
                        <li>
                            To monitor and analyze trends and better understand how users interact with our Services,
                            which helps us improve our Services and make them easier to use.
                        </li>
                        <li>
                            To monitor and protect the security of our Services, detect and prevent fraudulent transactions and other illegal activities,
                            fight spam, and protect the rights and property of us and others.
                        </li>
                        <li>
                            To communicate with you about offers and promotions offered by us and others we think will be of interest to you,
                            solicit your feedback, or keep you up to date on us and our products.
                        </li>
                    </ul>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We keep your information safe.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>How we protect information</SegmentSubTitle>
                    <p>
                        We take security seriously. We force HTTPS, meaning data between websites and our servers is always encrypted.
                        Data stored in our database is encrypted at rest. We don't store credit card data on our servers.
                        All payment processing is handled by Stripe, a certified Level 1 PCI Service Provider
                        (the most stringent level of certification available).
                        When credit card data is submitted via the Service it is sent directly to Stripe via JavaScript over a secure SSL connection.
                        The payment data never touches our servers.
                        We regularly take backups of all critical application data with a secure backup provider.
                        We rapidly investigate all reported security issues. If you've discovered a security bug, please send an email to security@vimkit.com
                        We will try to respond within 24 hours (usually faster).
                        We request that you not publicly disclose the issue until we can address it.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We don't sell your information.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>How we share information</SegmentSubTitle>
                    <p>
                        We do not sell your, or your end user’s private personal information.
                        We share information about you in the limited circumstances spelled out below and with appropriate safeguards on your privacy:
                    </p>
                    <ul>
                        <li>
                            Subsidiaries, Employees, and Independent Contractors: We may disclose information about you to our subsidiaries,
                            our employees, and individuals who are our independent contractors that need to know the information
                            in order to help us provide our Services or to process the information on our behalf. We require our subsidiaries,
                            employees, and independent contractors to follow this privacy policy for personal information that we share with them.
                        </li>
                        <li>
                            Third Party Vendors: We may share information about you with third party vendors who need to know
                            information about you in order to provide their services to us. This group includes vendors that help us provide
                            our Services to you (like payment providers that process your credit and debit card information)
                            and those that help us understand and enhance our Services (like analytics providers).
                        </li>
                        <li>
                            As Required by Law: We may disclose information about you in response to a subpoena, court order,
                            or other governmental request.
                        </li>
                        <li>
                            To Protect Rights and Property: We may disclose information about you when we believe in good faith that
                            disclosure is reasonably necessary to protect our property or rights, those of third parties, or the public at large.
                            For example, if we have a good faith belief that there is an imminent danger of death or serious physical injury,
                            we may disclose information related to the emergency without delay.
                        </li>
                        <li>
                            Business Transfers: In connection with any merger, sale of company assets, or acquisition of all or a portion
                            of our business by another company, or in the unlikely event that we go out of business or enter bankruptcy,
                            user information would likely be one of the assets that is transferred or acquired by a third party.
                            If any of these events were to happen, this privacy policy would continue to apply to your information
                            and the party receiving your information may continue to use your information,
                            but only consistent with this privacy policy.
                        </li>
                        <li>
                            With Your Consent: We may share and disclose information with your consent or at your direction.
                            For example, we may share your information with third parties with which you authorize us to do so.
                        </li>
                        <li>
                            Aggregated and De-Identified Information: We may share information that has been aggregated
                            or reasonably de-identified, so that the information could not reasonably be used to identify you.
                            For instance, we may publish aggregate statistics about the use of our Services.
                        </li>
                        <li>
                            Published Support Requests: And if you send us a request (for example, via a support email or one of our feedback mechanisms),
                            we reserve the right to publish that request in order to help us clarify or respond to your request
                            or to help us support other users.
                        </li>
                    </ul>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            As a global services we may need to transfer data to other countries.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Transferring information</SegmentSubTitle>
                    <p>
                        This is a worldwide service. By accessing or using the Services or otherwise providing information to us,
                        you consent to the processing, transfer, and storage of information in and to the U.S. and other countries,
                        which may have rights and protections that are different from those in your home country.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You can delete your information anytime.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Your data protection rights</SegmentSubTitle>
                    <p>
                        Our customers may benefit from a number of rights in relation to their information that we process.
                        Some rights apply only in certain limited cases, depending on your location.
                        If you would like to manage, change, limit, or delete your personal information,
                        you can do so via your Account settings or by contacting us. To delete your Account and all personal data,
                        please email your request to support@vimkit.com.
                        Upon request, we will provide you with information about whether we hold any of your personal information.
                        By visiting your Account settings, you can access, correct, change, and delete certain personal information
                        associated with your account. In certain cases where we process your information, you may also have a right to restrict
                        or limit the ways in which we use your personal information. In certain circumstances,
                        you also have the right to request the deletion of your personal information,
                        and to obtain a copy of your personal information in an easily accessible format.
                    </p>
                    <p>
                        If we process your information based on our legitimate interests as explained above, or in the public interest,
                        you can object to this processing in certain circumstances. In such cases,
                        we will cease processing your information unless we have compelling legitimate grounds to continue processing
                        or where it is needed for legal reasons.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You must protect your end user's information.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Your obligations to respect end user’s rights</SegmentSubTitle>
                    <p>
                        In connection with using our Services, you may receive and determine what to do with certain personal information from your users,
                        such as when communicating with them and entering into transactions with them. This means you process personal information
                        (for example, user name, email address, and billing address) and, to the extent you do so,
                        you are an independent controller of data relating to other users that you may have obtained through the Services.
                        You are responsible for protecting user's personal information you receive or process
                        and complying with all relevant legal requirements when you use the Services.
                        This includes applicable data protection and privacy laws that govern the ways in which you can use a user’s information.
                        Such laws may require that you post, and comply with, your own privacy policy,
                        which must be accessible to your users and compatible with this policy and our 
                        <HelpLink to="/terms" display="inline" fontWeight="600"> terms of use</HelpLink>.
                        As a data controller, to the extent that you process users’ personal information outside of the Services,
                        you may be required under applicable data protection and privacy laws to honor requests for data access, portability,
                        correction, deletion, and objections to processing. Also, if you disclose personal information without the user’s proper consent,
                        you are responsible for that unauthorized disclosure. This includes, for example, disclosures you intentionally make
                        or unintentional data breaches. For example, you may receive a user’s email address or other information
                        as a result of providing services to that user. This information may only be used for the authorized purpose.
                        You may not use this information for unsolicited commercial messages or unauthorized transactions.
                        Without the user’s consent, and subject to our Terms and all applicable laws, you may not add any user to your email
                        or physical mailing list, use that user’s identity for marketing, or obtain or retain any payment information.
                        Please bear in mind that you're responsible for knowing the standard of consent required in any given instance.
                    </p>
                    <p>
                        If we are found to be joint data controllers of personal information with you, and if we are sued, fined,
                        or otherwise incur expenses because of something that you did as a joint data controller of users’ personal information,
                        you agree to indemnify us for the expenses we occur in connection with your processing of users’ personal information.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We'll ask your end users' to talk to you about any privacy concerns.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>End users</SegmentSubTitle>
                    <p>
                        If you are a visitor of a Customer Site, this section applies to you. 
                        This section should always be read in conjunction with the specific privacy policy and terms of use,
                        if any, of the Customer Site which will contain further details regarding the processing of your personal data
                        by the Customer Site. You can get more information about our Service by visiting the help section of this site.
                        We assist our users/customers in providing their end users with a better experience and service
                        as well as assist them in diagnosing technical problems and analyzing user trends.
                        Most importantly, through our Services, the functionality of the Customer Site can be improved,
                        making them more user-friendly, more valuable, and simpler to use for the end users.
                        We have no direct relationship with the end users whose personal data a Customer Site processes.
                        An individual end user who seeks access, or who seeks to correct, amend,
                        or delete inaccurate data should direct their query to the Customer Site(the data controller)
                        you are using. If requested to remove data we will respond within a reasonable timeframe.
                        In certain circumstances we may be required by law to retain your personal information,
                        or may need to retain your personal information in order to continue providing a service.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Check third party services' privacy policies before you use them.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Third-party software</SegmentSubTitle>
                    <p>
                        Our Service allows you to integrate with various online third-party software.
                         In order to take advantage of this feature, you may need to authenticate, register for or log into third-party software
                         through the Service or on the websites of their respective providers.
                         When you enable linking between or log in to third-party software through the Service,
                         we will collect relevant information necessary to enable the Service to access that third-party software
                         such as your API keys or OAuth tokens (“Credentials”) to access the third party service, your data
                         and contained within that third-party software. We store your Credentials to access the third-party software
                         to the extent required to enable linking to the third-party software. When you enable the Service
                         to link content and data between third-party software, the third-party software will provide us with access
                         to certain information that you may have provided to the third-party software,
                         and we will use, store and disclose such information in accordance with this privacy policy
                         and the rules you set to govern the linking. In addition, you can use the Service to share content
                         and personal data between the third-party software you integrate with the Service.

                        If you’d like to use third-party software/services with our Services,
                        please keep in mind that when you interact with them you may provide information about yourself to those third-parties.
                        We don’t own or control these third-parties and they have their own rules about collection, use and sharing of information.
                        You should review their rules and policies when integrating and using any third-party software.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            If you share information publicly, others will be able to access it.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Information shared publicly</SegmentSubTitle>
                    <p>
                        Information that you choose to make public can be disclosed publicly.
                        That means, of course, that information like any content that you make public on your website is all available to others.
                        Public information may also be indexed by search engines or used by third parties.
                        Please keep all of this in mind when deciding what you would like to share.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You can opt out of communications anytime.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Opting out of communications</SegmentSubTitle>
                    <p>
                        You may opt out of receiving promotional messages from us. Just follow the instructions in those messages.
                        If you opt out of promotional messages, we may still send you other messages, like those about your account and legal notices.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You can opt out of cookies too.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Opting out of cookies</SegmentSubTitle>
                    <p>
                        If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website.
                        Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether,
                        or to notify you when a website is trying to set or update a cookie. If you browse websites from multiple devices,
                        you may need to update your settings on each individual device.
                    </p>
                    <p>
                        Although some cookies can be blocked with little impact on your experience of a website,
                        blocking all cookies may mean you are unable to access certain features and content across the sites you visit.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We comply with European privacy laws.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Legal basis for processing personal information</SegmentSubTitle>
                    <p>
                        This section applies only to European Economic Area visitors. We will normally collect personal information 
                         from you only where we have your consent to do so, where we need the personal information to perform a contract with you,
                         or where the processing is in our legitimate interests and not overridden by your data protection interests
                         or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect personal information from you.
                         If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you,
                         we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory
                         or not (as well as of the possible consequences if you do not provide your personal information).
                         Similarly, if we collect and use your personal information in reliance on our legitimate interests (or those of any third-party),
                         we will make clear to you at the relevant time what those legitimate interests are.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We comply with California privacy laws.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>California privacy rights</SegmentSubTitle>
                    <p>
                        This section applies only to California consumers. It describes how we collect, use,
                         and share California consumers' Personal Information in our role as a business, and the rights applicable to such residents.
                         The California Consumer Privacy Act ("CCPA") requires businesses to disclose whether they sell Personal Data.
                         We do not sell Personal Data. We may share Personal Data with third parties if those third parties
                         are authorized service providers or business partners who have agreed to our contractual limitations as to their retention,
                         use, and disclosure of such Personal Data.
                    </p>
                    <p>
                        If you are unable to access this Privacy Policy due to a disability or any physical or mental impairment,
                         please contact us and we will arrange to supply you with the information you need in an alternative format that you can access.
                         For purposes of this section "Personal Information" has the meaning given in the California Consumer Privacy Act (“CCPA”).
                    </p>
                    <p>
                        We have collected the following statutory categories of Personal Information in the past twelve (12) months:
                        <ul>
                            <li>
                                Identifiers, such as name, e-mail address, mailing address, and phone number.
                                 We collect this information directly from you or from third party sources.
                            </li>
                            <li>
                                Internet or network information, such as browsing and search history.
                                 We collect this information directly from your device.
                            </li>
                            <li>
                                Geolocation data, such as IP address. We collect this information from your device.
                            </li>
                            <li>
                                Financial information, such as Payment Information or financial account numbers
                                 in the process of providing you with a subscription. We collect this information from you.
                            </li>
                            <li>
                                Other personal information, in instances when you interact with us online,
                                 by phone or mail in the context of receiving help through our help desks or other support channels,
                                 participation in customer surveys or contests or in providing the Service.
                            </li>
                        </ul>
                        The business purposes for which we collect this information are described in the “How we use information” 
                         Section of this Privacy Policy. The categories of third parties to whom we "disclose" this information
                         for a business purpose are described in “How we share information” Section of this Privacy Policy.
                    </p>
                    <p>
                        Your California rights:<br/>
                        You have certain rights regarding the Personal Information we collect or maintain about you.
                         Please note these rights are not absolute, and there may be cases when we decline your request as permitted by law.
                        <ul>
                            <li>
                                The right of access means that you have the right to request that we disclose what Personal Information we have collected,
                                 used and disclosed about you in the past 12 months.
                            </li>
                            <li>
                                The right of deletion means that you have the right to request that we delete Personal Information collected
                                 or maintained by us, subject to certain exceptions. 
                            </li>
                            <li>
                                The right to non-discrimination means that you will not receive any discriminatory treatment
                                 when you exercise one of your privacy rights.
                            </li>
                        </ul>
                    </p>
                    <p>
                        How to exercise your California rights:<br/>
                        You can exercise your rights yourself or you can alternatively designate an authorized agent to exercise these rights
                         on your behalf. Please note that to protect your Personal Information, we will verify your identity by a method appropriate
                         to the type of request you are making. We may also request that your authorized agent have written permission
                         from you to make requests on your behalf, and we may also need to verify your authorized agent's identity
                         to protect your Personal Information. Please contact us at privacy@vimkit.com, if you would like to:
                        <ul>
                            <li>
                                Access this policy in an alternative format
                            </li>
                            <li>
                                Exercise your rights
                            </li>
                            <li>
                                Learn more about your rights or our privacy practices
                            </li>
                            <li>
                                Designate an authorized agent to make a request on your behalf
                            </li>
                        </ul>
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We'll tell you if we change this policy.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Changes</SegmentSubTitle>
                    <p>
                        Although most changes are likely to be minor, we may change this privacy policy from time to time.
                        We encourage visitors to frequently check this page for any changes to its privacy policy.
                        If we make changes, we will notify you by revising the change log below, and, in some cases,
                        we may provide additional notice (such as adding a statement to our homepage or sending you a notification through
                        e-mail or your dashboard). Your continued use of the Services after any change in this privacy policy will
                        constitute your consent to such change.
                    </p>
                </Segment>
                <TextDiv 
                    color={theme.colors.extraSubtleText} 
                    marginTop="20px" 
                    marginBottom="20px" 
                    breakpoint={theme.breakpoints.mobile} 
                    responsiveAlign="center"
                >
                    Last updated on Feb 19, 2022.
                </TextDiv>
            </Section>
        </SiteLayout>    
    )
}

export default Privacy
